import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3 from 'web3';
import Moralis from 'moralis';

// @ts-ignore
export default class CoinbaseConnector extends Moralis?.AbstractWeb3Connector {
    // A name for the connector to reference it easy later on
    type = 'coinbase';
    account: string | null = null;
    chainId: string | null = null;
    provider: unknown = null;
    coinbaseWallet = new CoinbaseWalletSDK({
        appName: 'coinbase?',
        appLogoUrl: 'https://example.com/logo.png',
        darkMode: false,
    });

    /**
     * A function to connect to the provider
     * This function should return an EIP1193 provider (which is the case with most wallets)
     * It should also return the account and chainId, if possible
     */
    async activate() {
        const ethereum = this.coinbaseWallet.makeWeb3Provider(
            `https://eth-mainnet.alchemyapi.io/v2/SvQdCXqVapYPfoOQ7qXioSNi_xm6R663`,
            1
        );

        // Store the EIP-1193 provider, account and chainId
        await ethereum.enable();
        const web3 = new Web3(ethereum);
        const accounts = await web3.eth.getAccounts();
        this.account = accounts[0];
        this.chainId = '0x1'; // Should be in hex format
        this.provider = ethereum;

        // Call the subscribeToEvents from AbstractWeb3Connector to handle events like accountsChange and chainChange
        // @ts-ignore
        this.subscribeToEvents(this.provider);

        // Return the provider, account and chainId
        return {
            provider: this.provider,
            chainId: this.chainId,
            account: this.account,
        };
    }

    // Cleanup any references to torus
    async deactivate() {
        // Call the unsubscribeToEvents from AbstractWeb3Connector to handle events like accountsChange and chainChange
        // @ts-ignore
        this.unsubscribeToEvents(this.provider);

        this.coinbaseWallet.disconnect();

        this.account = null;
        this.chainId = null;
        this.provider = null;
    }
}
